<template>
  <div class="error-message-container" v-if="error.isError">
    <div class="error-message">{{ error.message }}</div>
  </div>
  <div class="row reverse-on-mobile">
    <div class="column form-column" v-if="success">
      <div class="success-message">Dziękujemy za poparcie projektu 7-godzinnego dnia pracy! ❤</div>
    </div>
    <div class="column form-column" v-if="!success">
      <Form @submit="submit" :validation-schema="schema" class="form" v-slot="{ meta, values }">

        <div class="input">
          <label for="name">Imię*</label>
          <Field placeholder="Imię" name="name" id="name" autocomplete="given-name"/>
          <ErrorMessage name="name"/>
        </div>
        <div class="input">
          <label for="surname">Nazwisko*</label>
          <Field placeholder="Nazwisko" name="surname" id="surname" autocomplete="family-name"/>
          <ErrorMessage name="surname"/>
        </div>
        <div class="input">
          <label for="email">Email*</label>
          <Field placeholder="Email" name="email" id="email" autocomplete="email"/>
          <ErrorMessage name="email"/>
        </div>
        <div class="input">
          <label for="country">Kraj*</label>
          <Field placeholder="Kraj" name="country" id="country" modelValue="Polska"/>
          <ErrorMessage name="country"/>
        </div>
        <div class="input">
          <label for="city">Miejscowość*</label>
          <Field placeholder="Miejscowość" name="city" id="city"/>
          <ErrorMessage name="city"/>
        </div>
        <div class="input">
          <label for="telephone">Telefon</label>
          <Field placeholder="Telefon" name="telephone" id="telephone" autocomplete="tel"/>
          <ErrorMessage name="telephone"/>
        </div>


        <div class="privacy-policy">
          Przed wypełnieniem zgłoszenia zapoznaj się z naszą <a
            href="https://partiarazem.pl/polityka-prywatnosci"
            target="_blank"
            rel="noopener noreferrer">
          polityką prywatności</a>.
        </div>

        <div class="input checkbox">
          <Field v-slot="{ field }" name="terms" id="terms" type="checkbox" :value="true">
          </Field>
          <label for="terms">
            Wyrażam zgodę na przetwarzanie danych w celu obsługi wyrażonego przez formularz poparcia inicjatywy Pracujmy krócej i przesyłania informacji dotyczących tej inicjatywy.
          </label>
        </div>

        <ErrorMessage name="terms" class="checkbox-error"/>

        <div class="input checkbox">
          <Field v-slot="{ field }" name="newsletter" id="newsletter" type="checkbox" :value="true">
          </Field>
          <label for="newsletter">
            Wyrażam zgodę na przetwarzanie adresu email w celu otrzymywania wiadomości od partii Razem, informacji o
            jej działaniach oraz kampaniach. Zgoda może być wycofana w każdej chwili.
          </label>
        </div>

        <ErrorMessage name="newsletter" class="checkbox-error"/>

        <div class="button-container">
          <button class="button" :class="{ disabled: !meta.valid || sending }">
            <span v-if="sending">WYSYŁANIE...</span>
            <span v-else>POPIERAM</span>
          </button>
        </div>
      </Form>

      <div class="recaptcha">
        <p>
          Korzystamy z reCAPTCHA od Google:
          <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">polityka
            prywatności</a> i <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer noopener">warunki
          korzystania</a>.
        </p>
        <vue-recaptcha v-if="loaded" ref="recaptcha" :sitekey="siteKey" size="invisible" @verify="verifyCaptcha" @expired="expiredCaptcha"/>
      </div>
    </div>

    <div class="column signed-column">
      <div class="statistics" v-if="loaded">
        <div class="signed"><span class="accent">{{ signatures }}</span> podpisało.</div>
        <div class="signed small">Niech będzie nas <strong>{{ maximumSignatures }}</strong>!</div>
        <div class="progress-bar">
          <k-progress
              line-height="20"
              :active="true"
              active-color="#ba0c68"
              :show-text="false"
              :flow-second="3"
              color='#870F57'
              type="line"
              :percent=calculatePercent></k-progress>
        </div>
        <div class="list">
          <div class="person" v-for="index in people.length" :key="index">
            <div class="person"><strong>{{ people[index - 1].name }} {{ people[index - 1].surname }}.</strong>
              podpisał/a
              {{ parseDate(people[index - 1].created_at) }}
            </div>
          </div>
        </div>
      </div>
      <div class="img-container hide-on-mobile">
        <img src="../img/7h-img.png" alt="Mniej znaczy więcej. 7h > 8h" class="logo">
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { VueRecaptcha } from 'vue-recaptcha';
import * as yup from 'yup';
import { configure } from 'vee-validate';

import { DateTime } from 'luxon';

configure({
  validateOnInput: true,
});

// Both regexes need to match an empty string, so that they pass if the field is left blank
const phoneAllowedGlyphs = /^\+?\d*$/;
const polishPhone = /^((\+48)?\d{9})?$/;

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    VueRecaptcha,
  },
  data() {
    const schema = yup.object()
        .shape({
          email: yup.string()
              .required('Pole wymagane')
              .email('Wprowadź prawidłowy adres email.'),
          name: yup.string()
              .required('Pole wymagane')
              .max(120, 'Maksymalna długość pola to 120 znaków.'),
          surname: yup.string()
              .required('Pole wymagane')
              .max(120, 'Maksymalna długość pola to 120 znaków.')
              .default('Polska'),
          city: yup.string()
              .required('Pole wymagane')
              .max(120, 'Maksymalna długość pola to 120 znaków.'),
          country: yup.string()
              .required('Pole wymagane')
              .max(120, 'Maksymalna długość pola to 120 znaków.'),
          telephone: yup.string()
              .nullable()
              .notRequired()
              .when('country', {
                is: (value) => value === 'Polska',
                then: (rule) => rule.matches(polishPhone, 'musi być odpowiedniej długości')
                    .matches(phoneAllowedGlyphs, 'może zawierać tylko znak + i cyfry'),
                otherwise: (rule) => rule
                    // Currently probably only Niue has 7 digit phone numbers (including country code) so we can disregard it.
                    // See: https://stackoverflow.com/a/43993566/1778478
                    // Also, the yup's built-in .min matcher doesn't pass for value === '', so a custom test is needed.
                    .test('min', 'musi mieć co najmniej 9 znaków', (value) => !(value?.length) || value.length >= 9)
                    .matches(phoneAllowedGlyphs, 'może zawierać tylko znak + i cyfry')
              }),
          terms: yup.bool()
              .required('Zgoda na przetwarzanie danych jest wymagana.'),
          newsletter: yup.bool(),
        });

    return {
      schema,
      signatures: 0,
      success: false,
      loaded: false,
      siteKey: '',
      sending: false,
      error: {
        isError: false,
        message: 'Błąd serwera! Jeśli będzie się powtarzał, prosimy o kontakt.'
      },
      values: {},
      token: '',
      people: []
    };
  },
  computed: {
    maximumSignatures() {
      if (this.signatures < 80) {
        return 100;
      } else if (this.signatures < 400) {
        return 500;
      } else if (this.signatures < 800) {
        return 1000;
      } else if (this.signatures < 4500) {
        return 5000;
      } else if (this.signatures < 6500) {
        return 7500;
      } else if (this.signatures < 9000) {
        return 10000;
      } else if (this.signatures < 14000) {
        return 15000;
      } else if (this.signatures < 19000) {
        return 20000;
      } else {
        return 100000;
      }
    },
    calculatePercent() {
      return Math.round((this.signatures / this.maximumSignatures) * 100);
    },
  },
  async created() {
    this.siteKey = await fetch('/api/config')
        .then((res) => res.json())
        .then((config) => {
          return config.recaptchaSiteKey;
        })
        .catch(() => {
          console.error('There was a problem with fetching captcha site key');
          return '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
        }); // fallback to always-passing key

    await Promise.all([
      this.getSignatures(),
      this.getCounter()
    ])
        .then(() => {
          this.loaded = true;
        });
  },
  methods: {
    async submit(values) {
      this.values = values;
      this.sending = true;
      if (this.token.length === 0) {
        this.$refs.recaptcha.execute();
      } else {
        await this.verifyCaptcha(this.token);
      }
    },
    async verifyCaptcha(token) {
      this.token = token;
      await this.submitForm(this.values, token);
      this.sending = false;

      await Promise.all([
        this.getSignatures(),
        this.getCounter()
      ]);
    },
    expiredCaptcha() {
      this.$refs.recaptcha.reset();
    },
    parseDate(date) {
      return DateTime.fromISO(date)
          .toRelative({ locale: 'pl' });
    },
    async submitForm(values, token) {
      await fetch(`/api/signatures/sign?recaptchaResponse=${token}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          }
      )
          .then(response => {
            if (response.ok) {
              this.success = true;
              this.error.isError = false;
            } else {
              this.error.isError = true;
              response.json()
                  .then(error => {
                    this.error.message = error.error;
                  });
            }
          })
          .catch(error => {
            this.error.isError = true;
            this.error.message = 'Problem z wysłaniem formularza. Jeśli błąd będzie się powtarzał, prosimy o kontakt.';
          });

    },
    async getCounter() {
      await fetch('/api/signatures/counter', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          }
      )
          .then(response => response.json())
          .then(data => {
            this.signatures = data.signatures;
          })
          .catch(error => {
            this.error.isError = true;
            this.error.message = 'Problem z pobraniem informacji o podpisach. Jeśli błąd będzie się powtarzał, prosimy o kontakt.';
          });
    },
    async getSignatures() {
      await fetch('/api/signatures/last-signed', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          }
      )
          .then(response => response.json())
          .then(data => {
            this.people = data.people;
          })
          .catch(error => {
            this.error.isError = true;
            this.error.message = 'Problem z pobraniem informacji o podpisach. Jeśli błąd będzie się powtarzał, prosimy o kontakt.';
          });
    }
  }
};

</script>

<style lang="scss">

.grecaptcha-badge {
  visibility: hidden;
}

.error-message-container {
  text-align: center;
  background-color: var(--color-work-default);
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-2);

  .error-message {
    font-size: var(--fontSize-2);
    color: var(--color-razem-10);
    font-weight: var(--fontWeight-bold);
  }
}

.success-message {
  font-size: var(--fontSize-2);
  color: var(--color-work-default);
  font-weight: var(--fontWeight-bold);
}

.column.form-column {
  flex: 65%;
  text-align: center;
}

.column.signed-column {
  flex: 35%;
  width: 100%;
  padding: var(--spacing-0);

  .progress-bar {
    padding: var(--spacing-6) 0;

    .k-progress-outer {
      padding-right: 0;
    }
  }

  .statistics {
    background: var(--color-razem-100);
    padding: var(--spacing-6);
  }

  .img-container {
    padding-top: var(--spacing-4);

    img {
      width: 100%;
    }
  }

  .list {
    padding: var(--spacing-4) 0;
    color: var(--color-gray-900);
  }

  .signed {
    font-family: var(--fontFamilyHeadings);
    font-weight: var(--fontWeight-bold);
    color: var(--color-heading);
    font-size: var(--fontSize-4);

    &.small {
      font-size: var(--fontSize-2);
    }

    .accent {
      color: var(--color-work-default);
    }
  }
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.button-container {
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
  margin-top: var(--spacing-2);
}

.form {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: var(--spacing-8);
  text-align: left;

  .privacy-policy {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
  }

  .checkbox-error {
    grid-column-start: 1;
    grid-column-end: 3;
    color: var(--color-work-3);
  }

  .input {
    font-size: 1.1rem;
    font-weight: var(--fontWeight-black);
    display: grid;
    gap: 0.3rem;
    position: relative;

    label {
      font-size: var(--fontSize-2);
      text-transform: uppercase;
      font-weight: var(--font-weight-headline);
      color: var(--color-gray-900);
    }

    span {
      font-size: var(--fontSize-1);
      font-weight: var(--fontWeight-normal);
      position: absolute;
      color: var(--color-work-3);
      right: 0;
      top: 5px;
    }

    &.checkbox {
      grid-column-start: 1;
      grid-column-end: 3;
      display: flex;
      align-items: flex-start;

      label {
        text-transform: none;
        font-size: var(--fontSize-1);
        padding-left: var(--spacing-8);
      }

      input {
        width: auto;
        margin-right: 1rem;
        margin-top: .3em;
        filter: invert(0%) hue-rotate(110deg) brightness(1.1);

        &, &:focus {
          box-shadow: none;
        }
      }
    }

    input {
      padding: 1rem;
      font-size: 1rem;
      border: none;
      box-shadow: 0 0 0 2px var(--color-primary);
      border-radius: 10px;
      width: 100%;

      &:not([type="checkbox"]) {
        -webkit-appearance: none;
      }

      &:focus {
        box-shadow: 0 0 0 2px var(--color-work-3);
        outline: none;
      }
    }
  }
}

.recaptcha {
  text-align: center;
  margin-top: var(--spacing-4);

  p {
    font-size: var(--fontSize-1);
  }
}

@media (max-width: 54rem) {
  .hide-on-mobile {
    display: none;
  }

  .row.reverse-on-mobile {
    flex-direction: column-reverse;
  }

  .form {
    grid-template-columns: 1fr;

    .privacy-policy {
      grid-column-end: 1;
    }

    .checkbox-error {
      grid-column-end: 1;
    }

    .input {
      &.checkbox {
        grid-column-end: 1;
      }
    }
  }

  .button-container {
    grid-column-end: 1;
  }

}

</style>
