<template>
  <nav class="desktop">
    <div class="logo-container">
      <router-link to="/"><img src="../img/7g-logo.png" alt="Logo projektu 7h" class="logo"></router-link>
    </div>
    <div>
      <router-link to="/">Pracujmy krócej!</router-link>
    </div>
    <div>
      <router-link to="/o-projekcie">O projekcie</router-link>
    </div>
    <div>
      <router-link to="/wspieram">Poprzyj</router-link>
    </div>
  </nav>
</template>


<style lang="scss">

nav.desktop {
  display: flex;
}

nav.desktop {
  padding: var(--spacing-6) var(--spacing-6);
  font-family: var(--fontFamilyHeadingsBold);
  font-size: var(--fontSize-1);

  .logo-container {
    width: 180px;
    position: relative;
    padding: var(--spacing-0) var(--spacing-6);

    .logo {
      width: 150px;
      position: absolute;
      top: calc(var(--spacing-6) * -1);
    }
  }

  div {
    padding: var(--spacing-0) var(--spacing-8);
  }

  a {
    color: var(--color-gray-900);

    &:hover {
      color: var(--color-work-default);
    }
  }
}

@media (max-width: 54rem) {
  nav.desktop {
    display: none;
  }
}
</style>
