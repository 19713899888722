<template>
  <div class="page-wrapper">
    <div class="section main">
      <h2 class="main-heading">O projekcie</h2>
      <p>Wychodząc naprzeciw palącej potrzebie społecznej – ulżeniu przepracowanym i przemęczonym pracownicom i
        pracownikom w Polsce – <strong>inicjatywa ma na celu zmniejszenie ogólnego wymiaru czasu pracy z 40 do
          <span class="accent">35 godzin</span> w
          tygodniu</strong>. Pozwoli to realizację takich społecznie pożytecznych celów jak:</p>
      <ul>
        <li>zwiększenie czasu, który pracownicy i pracownice mogą spędzać z rodziną czy na rozwoju osobistym,</li>
        <li>zwiększenie wydajności dzięki dłuższemu czasowi na odpoczynek pomiędzy zmianami, ochrona zdrowia polskich
          pracowników i pracownic,
        </li>
        <li>nieznaczne zwiększenie liczby miejsc pracy w Polsce,</li>
      </ul>

    </div>
    <div class="highlight">
      Zmniejszenie wymiaru pracy odbędzie się bez wpływu na miesięczne dochody osób pracujących obecnie w wymiarze 40
      i więcej godzin w tygodniu – zmniejszenie czasu pracy nie powinno mieć wpływu na bezwzględny poziom miesięcznych
      zarobków pracowników i pracownic w Polsce.
    </div>
    <div class="section">
      <p> Rosnąca wydajność pracy (przez ostatnie dwadzieścia lat Polska uzyskała najwyższy wzrost wśród krajów OECD)
        nie przekłada się na odpowiadający jej wzrost wynagrodzeń. Coraz większa intensywność pracy, wymóg ciągłej
        dostępności i nieograniczonej dyspozycyjności skutkują chronicznym zmęczeniem, wysokim poziomem stresu i
        problemami ze zdrowiem.</p>


      <p>Przeprowadzone pod dyktando biznesu „uelastycznienie rynku pracy”, normalizacja umów śmieciowych, outsourcing
        przez agencje pracy tymczasowej, wypychanie pracowników na fikcyjne samozatrudnienie w połączeniu z upadkiem
        tradycyjnych gałęzi przemysłu i wzrostem sektora usług stworzyły toksyczną sytuację, w której umowa o pracę jest
        przywilejem, na który pracownik musi sobie zasłużyć.</p>


      <p>Według raportów Hays Polska, <strong>niemal 75% zatrudnionych w Polsce świadczy pracę w nadgodzinach, ponad
        połowa z
        nich nie otrzymuje za tę pracę wynagrodzenia – czyli pracę w godzinach nadliczbowych świadczy
        nieodpłatnie. </strong>62%
        pracujących w nadgodzinach skarży się na stres, przemęczenie i inne dolegliwości związane z pracą. O skali
        problemu świadczą doniesienia Państwowej Inspekcji Pracy – kontrole wykazują przypadki osób, które w ciągu kilku
        lat wyrobiły nawet 700 godzin nadliczbowych, za które nie otrzymały wynagrodzenia ani dnia wolnego.</p>

      <div class="row padding-bottom">

        <div class="column">
          <div class="img-container">
            <img src="../../img/grucela.jpg?width=800&quality=80" alt="Bartosz Grucela - Zarząd Krajowy Razem" class="photo">
          </div>
        </div>

        <div class="column">
          <div class="quote">
            <span class="quote-marker">„</span>
            W Polsce mamy istną epidemię chorób związanych z przepracowaniem, takich jak choroby
            układu krążenia, problemy z kręgosłupem czy depresja. To wszystko wpływa na całą
            gospodarkę, na wydajność pracowników, na koszty ich leczenia. Przekonanie, że jeśli
            będziemy pracować ponad siły zbudujemy dobrobyt jest nieprawdziwe i najwyższy czas to
            zmienić!
          </div>
          <div class="quote-description"><strong><a href="https://twitter.com/BGrucela" target="_blank">Bartosz Grucela</a></strong> - Zarząd Krajowy Razem</div>
        </div>
      </div>

      <p>Bezwzględny wyzysk, któremu poddawani są imigranci ekonomiczni, żałośnie niski zasiłek dla bezrobotnych,
        słabość związków zawodowych i Państwowej Inspekcji Pracy sprawiły, że jedynym pomysłem na konkurencyjność
        polskiej gospodarki jest wyścig na dno poprzez cięcie kosztów i warunków pracy. Wydłużanie realnego dziennego i
        tygodniowego wymiaru czasu pracy jest natomiast najprostszym z punktu widzenia przedsiębiorców mechanizmem
        zwiększania obciążeń pracowników.</p>

      <p>Praca zarobkowa nie wyczerpuje listy obowiązków polskich pracownic i pracowników. Według danych OECD kobiety w
        Polsce codziennie świadczą nieodpłatną pracę w średnim wymiarze prawie 5 godzin (296 minut) dziennie – o blisko
        2,5 godziny więcej niż mężczyźni (159 minut). Polega ona na wykonywaniu tak zwanych „prac domowych”, takich jak
        sprzątanie czy gotowanie, oraz na opiece nad dziećmi.</p>

      <p><strong>Permanentne przemęczenie i brak czasu wolnego ma katastrofalne skutki dla polskiego społeczeństwa –
        rozpadowi
        ulegają więzi rodzinne i społeczne, spada aktywność obywatelska, pogarsza się stan zdrowia obywatelek i
        obywateli, wzrasta liczba osób uzależnionych od używek.</strong></p>

      <p>Wprowadzenie krótszego dnia pracy będzie wymagać nowelizacji zarówno Kodeksu Pracy, jak i innych aktów prawnych
        regulujących czas pracy poszczególnych grup zawodowych, m.in. służb mundurowych, kierowców i kierowczyń czy
        urzędniczek oraz urzędników.</p>

    </div>
    <img src="../../img/img1.jpg" class="divider img-project-1">

    <h3>Materiały do pobrania</h3>
    <ul>
      <li><a href="./35h-ustawa-i-uzasadnienie.pdf" target="_blank">projekt ustawy</a></li>
      <li><a href="./logo.pdf" target="_blank">logo</a></li>
      <li><a href="./plakat.pdf" target="_blank">plakat</a></li>
    </ul>
  </div>

</template>

<style lang="scss" scoped>
.img-project-1 {
  object-position: 100% 35%;
}

.img-container {
  .photo {
    width: 100%;
    border-radius: 30px;
  }
}

.quote {
  font-size: var(--fontSize-4);
  font-family: var(--fontFamilyQuote);
  font-weight: var(--fontWeight-normal);

  .quote-marker {
    font-size: var(--fontSize-8);
    line-height: 0;
    padding: 0 var(--spacing-4) 0 0;
    color: var(--color-primary);
  }
}

.quote-description {
  text-transform: uppercase;
  padding: var(--spacing-4) 0;
  font-family: var(--fontFamilyQuote);
  font-size: var(--fontSize-2);
}

.accent {
  color: var(--color-primary);
}

ul {
  list-style-position: inside;
  list-style-type: square;

  li {
    font-size: var(--fontSize-2);
    margin-bottom: var(--spacing-1);
  }
}

.page-wrapper .section.main {
  padding-bottom: var(--spacing-4);
}


@media (max-width: 74rem) and (min-width: 54rem) {

  .quote {
    font-size: var(--fontSize-2);
  }
}
</style>
