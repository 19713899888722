<template>
  <div class="page-wrapper">
    <div class="section main">
      <h2 class="main-heading">Pracujmy krócej!</h2>
      <p class="headline">Kampania na rzecz projektu zmniejszającego czas pracy do 7 godzin dziennie.</p>
      <router-link to="/o-projekcie"><div class="button">Dowiedz się więcej!</div></router-link>
    </div>

    <img src="../../img/img5.jpg" class="divider img-main-1">

    <div class="section thin">
      <h3>Dlaczego 7 godzin?</h3>
      <p>Świat poszedł do przodu, a my dalej pracujemy tyle, co sto lat temu. Pracujemy znacznie wydajniej i efektywniej
        –
        <strong>dlatego stać nas na skrócenie czasu pracy!</strong> Mniej godzin w pracy to więcej czasu dla rodziny i
        bliskich. To
        więcej
        czasu na odpoczynek i rozwijanie zainteresowań.</p>
      <router-link to="/o-projekcie"><div class="button">Czytaj więcej</div></router-link>
    </div>

    <div class="highlight">
      <div class="heading">1766</div>
      <div class="subheading">tyle godzin średnio spędzamy w pracy. To najwięcej w całej Europie!</div>
    </div>

    <div class="section wider">
      <div class="row">
        <div class="column">
          <img src="../../img/img3.jpg" class="img-main-2">
        </div>
        <div class="column">
          <h3>Pomóż w zbiórce</h3>
          <p>
            Chcesz krótszego czasu pracy?
            Możesz pomóc nam w naszej kampanii!
            Przekazaliśmy dotychczasowe podpisy wraz z projektem ustawy (zobacz go
            <a href="./35h-ustawa-i-uzasadnienie.pdf" target="_blank">tutaj</a>)
            polityczkom i politykom w polskim parlamencie.
            Złóż podpis z poparciem na naszej stronie i udostępnij
            <a href="https://facebook.com/pracujmykrocej" target="_blank">facebook.com/pracujmykrocej</a>
            swoim znajomym w mediach społecznościowych.
          </p>

          <router-link to="/wspieram"><div class="button">Poprzyj</div></router-link>
        </div>
      </div>
    </div>

    <div class="section wider">
      <div class="row">
        <div class="column">
          <h3>Wesprzyj finansowo</h3>
          <p>Kampania taka jak ta wymaga środków. Możesz wesprzeć nasze starania o 7-godzinny dzień pracy! Liczy się
            każda
            kwota.</p>
          <a href="https://wesprzyj.partiarazem.pl" target="_blank"><div class="button">Chcę wpłacić</div></a>
        </div>
        <div class="column">
          <img src="../../img/img2.jpg" class="img-main-2">
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss">
.img-main-1 {
  object-position: 50% 25%;
}

.img-main-2 {
  width: 100%;
  border-radius: 20px;
}
</style>
