<template>
  <div class="announcement">
    Projekt ustawy zmniejszający czas pracy do 7 godzin dziennie złożony w Sejmie!
    Zapoznaj się z jego treścią
    <a href="./35h-ustawa-i-uzasadnienie.pdf" target="_blank">tutaj</a>.
  </div>
  <nav class="mobile">
    <div class="logo-container">
      <router-link to="/"><img src="../img/7g-logo.png" alt="Logo projektu 7h" class="logo"></router-link>
    </div>
    <div class="links">
      <router-link to="/"><div>Pracujmy krócej!</div></router-link>
      <router-link to="/o-projekcie"><div>O projekcie</div></router-link>
      <router-link to="/wspieram"><div>Poprzyj</div></router-link>
    </div>
  </nav>
</template>


<style lang="scss">

.announcement {
  font-family: var(--fontFamilyHeadings);
  background: var(--color-work-8);
  color: var(--color-razem-10);
  padding: var(--spacing-2) var(--spacing-4);
  font-size: var(--fontSize-2);
  text-align: center;

  a {
    color: var(--color-razem-10);
    text-decoration: underline;
  }
}

nav.mobile {
  display: none;
}

nav.mobile {
  width: 100%;
  justify-content: space-between;
  padding: var(--spacing-0);

  .logo-container {
    padding: var(--spacing-0) var(--spacing-0);
    flex: 35%;
    .logo {
      max-width: 100%;
      width: 180px;
    }
  }

  div.links {
    flex: 65%;
  }

  div.links a div {
    cursor: pointer;
    padding: var(--spacing-2) var(--spacing-6);
    font-size: var(--fontSize-2);
    text-align: left;
    background: var(--color-work-default);
    font-family: var(--fontFamilyHeadingsBold);
    text-transform: uppercase;
    margin: var(--spacing-3) var(--spacing-0);
    color: var(--color-razem-10);

    &:hover {
      background: var(--color-work-8);
    }
  }

}

@media (max-width: 54rem) {
  nav.mobile {
    display: flex;
  }
}
</style>
