<template>
  <div class="main">
    <NavMobile></NavMobile>
    <Nav></Nav>
    <div class="container">
      <router-view/>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from './components/Nav.vue';
import NavMobile from './components/NavMobile.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Nav,
    NavMobile,
    Footer
  }
};
</script>

<style>
@import "./css/normalize.css";
@import "./css/main.scss";
@import "./css/app.scss";
</style>

<style lang="scss">



</style>
